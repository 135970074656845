import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
/*Service*/
import { AlertService } from '@app/core/services/alert.service';

/*Models*/
import { ExamService } from '@app/core/http/exam/exam.service';
import { ExamJr, ExamSr, RunTestSr } from '@app/shared/models/exam-schedule.model';
import { MessageService } from '@app/core/services/message.service';
declare const isMobileDevice: any;
@Component({
  selector: 'app-exam-schedule-sr',
  templateUrl: './exam-schedule-sr.component.html',
  styleUrls: ['./exam-schedule-sr.component.css']
})
export class ExamScheduleSrComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = false;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  examScheduleStartParm: any;
  examScheduleSr: ExamSr[];
  examSubmition = new RunTestSr();
  audio = new Audio();
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  singleExam: ExamSr;
  isQuestionLoad: boolean = false;
  sequenceNumber: number = 1;
  timeStart: number = 0;
  interval;
  clock: number = 2700;
  display;
  ClassCode: string = '';
  ClassLevel: string = '';
  OlympiadId: string = '';
  StudentTransactionId: string = '';

  isLastVideoPlay: boolean = false;
  markAsReview: boolean = false;
  currentChoosenAnswer = '';
  showConfirm: boolean = false;
  isMobileDevice: boolean = false;
  legendArr = [
    { txt: 'Answered', cnt: 0 },
    { txt: 'Yet To Answer', cnt: 0 },
    { txt: 'I Will Solve It Later', cnt: 0 }
  ];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private examService: ExamService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.ClassCode = params['ClassCode'];
      this.ClassLevel = params['ClassLevel'];
      this.OlympiadId = params['OlympiadId'];
      this.StudentTransactionId = params['StudentTransactionId'];

      if (this.ClassCode && this.ClassLevel && this.OlympiadId && this.StudentTransactionId) { // original test mode
        this.examScheduleStartParm = {
          "OlympiadId": this.OlympiadId,
          "ClassLevel": this.ClassLevel,
          'ClassCode': this.ClassCode,
          "StudentTransactionId": this.StudentTransactionId,
          "TestStatus": "STARTED",
          "IsFree": false,
          "BatchId": "",
          "TestSubmissionColl": []
        };
        this.getExamSchedule(this.examScheduleStartParm);
      } else if (this.ClassCode && this.ClassLevel && this.OlympiadId) { // demo test mode
        this.examScheduleStartParm = {
          "OlympiadId": this.OlympiadId,
          "ClassLevel": this.ClassLevel,
          'ClassCode': this.ClassCode,
          "TestStatus": "STARTED",
          "IsFree": true,
          "BatchId": "",
          "TestSubmissionColl": []
        };
        this.getExamSchedule(this.examScheduleStartParm);
      }
    });
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): any {
    if (error.mediaStreamError) {
      this.alertService.error('Web cam not found, please enable camera for browser.');
      return false;
    }
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {    
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  getExamSchedule(parms): void {
    this.spinnerService.show();
    this.examService.getExamSchedule(parms).subscribe(
      (response) => {
        if (response.StatusCode === 200) {
          this.spinnerService.hide();
          for (var i = 0; i < response.Result.length; i++) {
            response.Result[i].MarkAsReview = false;
          }
          this.examScheduleSr = response.Result;
          this.singleExam = response.Result[0];
          this.isMobileDevice = isMobileDevice();
          // fill array          
          let initArr = this.examScheduleSr.map(exQ => {
            return {
              BatchId: exQ.BatchId, QuestionId: exQ.QuestionId,
              ChoosenAnswer: "", TotalTime: 0, SequenceNumber: exQ.SequenceNumber,
              MarkAsReview: false
            }
          });
          this.examSubmition.TestSubmissionColl = initArr;
          //end of fill array
          if (this.singleExam == undefined) {
            console.log("questions not available");
          }
          console.log(this.singleExam.VideoFileName)
          if (this.singleExam.VideoFileName != null && this.singleExam.VideoFileName != "") {
            this.isQuestionLoad = false;
          } else {
            this.isQuestionLoad = true;
          }
          this.startClock();
          this.updateLegends();
        } else if (response.StatusCode === 204) {
          this.spinnerService.hide();
          this.alertService.warn(response.Message, { autoClose: true });

          this.router.navigateByUrl("exam-start-sr", {
            queryParams: {
              'ClassLevel': this.ClassLevel,
              'ClassCode': this.ClassCode,
              'OlympiadId': this.OlympiadId,
              'StudentTransactionId': this.StudentTransactionId
            }
          })
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.error(error.message, { autoClose: true });
      }
    )
  }

  ngAfterViewInit(): void {
    if (window.history && window.history.pushState) {
      $(window).on('popstate', function () {
        window.alert('You are logged out, as you have pressed browser back or forward.')
        sessionStorage.clear();
        window.location.reload(true);

      });
    }
  }

  next(sequenceNumber) {
    this.addTimeInCurrent();

    this.sequenceNumber++;
    sequenceNumber = this.sequenceNumber;
    this.singleExam = this.examScheduleSr.filter(element => element.SequenceNumber == sequenceNumber)[0];
    if (this.singleExam) {
      this.getCurrentChoosen();
      if (this.singleExam.SequenceNumber == 1) {
        this.startTimer();
      } else if (this.singleExam.VideoFileName == null || this.singleExam.VideoFileName == "") {
        this.isQuestionLoad = true; // !this.isQuestionLoad;
        this.resetTimer();
        if (this.singleExam.AudioFileName != null &&
          this.singleExam.AudioFileName != "") {
          this.audioPlay(this.singleExam.AudioFileName);
        }
      } else if (this.singleExam.VideoFileName != null) {
        this.audio.pause();
        this.isQuestionLoad = false;
        this.resetTimer();
      }
      this.updateLegends();
    }
  }
  previous(sequenceNumber) {
    this.addTimeInCurrent();

    this.sequenceNumber--;
    sequenceNumber = this.sequenceNumber;

    this.singleExam = this.examScheduleSr.filter(element => element.SequenceNumber == sequenceNumber)[0];
    if (this.singleExam) {
      this.getCurrentChoosen();
      if (this.singleExam.VideoFileName == null || this.singleExam.VideoFileName =="") {
        this.isQuestionLoad = true;
        this.resetTimer();
        if (this.singleExam.AudioFileName != null &&
          this.singleExam.AudioFileName != "") {
          this.audioPlay(this.singleExam.AudioFileName);
        }
      }
      this.updateLegends();
    }
  }
  goToQues(sequenceNumber) {
    this.addTimeInCurrent();
    this.sequenceNumber = sequenceNumber;
    this.singleExam = this.examScheduleSr.filter(element => element.SequenceNumber == sequenceNumber)[0];
    if (this.singleExam) {
      this.getCurrentChoosen();
      if (this.singleExam.VideoFileName == null || this.singleExam.VideoFileName == "") {
        this.isQuestionLoad = true;
        this.resetTimer();
        if (this.singleExam.AudioFileName != null &&
          this.singleExam.AudioFileName != "") {
          this.audioPlay(this.singleExam.AudioFileName);
        }
      } else if (this.singleExam.VideoFileName != null) {
        this.isQuestionLoad = false;
        this.audio.pause();
        this.resetTimer();
      }
      this.updateLegends();
    }
  }
  markChanged(e) {
    let existingQ = this.examSubmition.TestSubmissionColl.filter(e => e.QuestionId == this.singleExam.QuestionId)
    if (existingQ.length) {
      existingQ[0].MarkAsReview = e.target.checked;

      this.singleExam.MarkAsReview = e.target.checked;

      this.updateLegends();
    }
  }
  selectOptions(optionType, singleExam: ExamSr) {
    this.isQuestionLoad = true; // !this.isQuestionLoad;;

    //let selectedOption = {
    //  BatchId: singleExam.BatchId,
    //  QuestionId: singleExam.QuestionId,
    //  ChoosenAnswer: optionType,
    //  TotalTime: this.timeStart,
    //  SequenceNumber: singleExam.SequenceNumber,
    //  MarkAsReview : false
    //}
    //this.examSubmition.TestSubmissionColl.push(selectedOption);

    let existingQ = this.examSubmition.TestSubmissionColl.filter(e => e.QuestionId == singleExam.QuestionId)
    if (existingQ.length) {
      existingQ[0].ChoosenAnswer = optionType;
      existingQ[0].TotalTime += this.timeStart;
    }
    this.audio.pause();
    //this.pauseTimer();
    this.resetTimer();
    //this.sequenceNumber = singleExam.SequenceNumber;

    this.getCurrentChoosen();
    this.updateLegends();

    //this.sequenceNumber++;
    //if(singleExam.IsLastQuestion){
    //  this.examCompleted();
    //} else {
    //  this.spinnerService.show();
    //  setTimeout(()=>{
    //    this.spinnerService.hide();
    //    this.next(this.sequenceNumber);
    //  },500)
    //}
  }
  updateLegends() {
    let cntNoVisit = 0, cntNotAns = 0, cntAns = 0, marked = 0;
    for (var i = 0; i < this.examSubmition.TestSubmissionColl.length; i++) {
      if (this.examSubmition.TestSubmissionColl[i].ChoosenAnswer != '') {
        cntAns++;
      } else if (this.examSubmition.TestSubmissionColl[i].TotalTime > 0) {
        cntNotAns++;
      } else {
        cntNoVisit++;
      }
      if (this.examSubmition.TestSubmissionColl[i].MarkAsReview) {
        marked++;
      }

    }

    //this.legendArr[0].cnt = cntNoVisit;
    this.legendArr[0].cnt = cntAns;
    this.legendArr[1].cnt = cntNotAns;
    this.legendArr[2].cnt = marked;
  }
  getCurrentChoosen() {
    let f1 = this.examSubmition.TestSubmissionColl.filter(e => e.QuestionId == this.singleExam.QuestionId)
    if (f1.length) {
      //console.log(f1[0])
      this.currentChoosenAnswer = f1[0].ChoosenAnswer;
    }
  }
  addTimeInCurrent() {
    let existingQ = this.examSubmition.TestSubmissionColl.filter(e => e.QuestionId == this.singleExam.QuestionId)
    if (existingQ.length) {
      existingQ[0].TotalTime += this.timeStart;
    }
  }

  submitConfirmClicked() {
    this.singleExam = this.examScheduleSr[this.examScheduleSr.length - 1];
    if (this.singleExam.IsLastQuestion) {
      if (this.singleExam.LastVideoFileName != null && this.singleExam.LastVideoFileName != "") {
        this.isQuestionLoad = false;
        this.isLastVideoPlay = true;
      } else {
        this.examCompleted();
      }
    }
  }
  examCompleted() {
    this.examSubmition.StudentTransactionId = this.singleExam.StudentTransactionId;
    this.examSubmition.ClassCode = this.singleExam.ClassCode || this.ClassCode;
    this.examSubmition.OlympiadId = this.singleExam.OlympiadId || this.OlympiadId;
    this.examSubmition.BatchId = this.singleExam.BatchId;
    this.examSubmition.TestStatus = "COMPLETED";
    this.examSubmition.IsFree = this.examScheduleStartParm.IsFree;
    this.spinnerService.show();
    let apiName = 'RunTestSr';
    this.examService.examScheduleComplete(this.examSubmition, apiName).subscribe(
      (response) => {
        if (response.StatusCode === 200) {
          this.spinnerService.hide();
          this.router.navigate(["exam-complete-sr"],
            {
              queryParams: {
                'ClassLevel': this.ClassLevel,
                'registrationID': response.Result
              }
            })
        }
      },
      (error) => {
        this.spinnerService.hide();
        this.alertService.error(error.message, { autoClose: true });
      }
    )
  }

  audioPlay(audioFile) {
    this.audio.src = audioFile;
    this.audio.load();
    this.audio.play();
  }

  resetTimer() {
    this.pauseTimer();
    this.timeStart = 0;
    this.startTimer();
  }

  videoEnd() {
    this.isQuestionLoad = true; // !this.isQuestionLoad;
    this.resetTimer()
    if (this.singleExam.AudioFileName != null &&
      this.singleExam.AudioFileName != "") {
      this.audioPlay(this.singleExam.AudioFileName);
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeStart >= 0) {
        this.timeStart++;
      } else {
        this.timeStart = 0;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  startClock() {
    setInterval(() => {
      this.clock--;
      this.display = this.transform(this.clock)
    }, 1000);
    return true;
  }

  transform(value: number): string {
    let sec_num = value;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    let min = minutes < 10 ? '0' + minutes : minutes;
    let sec = seconds < 10 ? '0' + seconds : seconds;
    if (minutes == 5 && seconds == 0) {
      console.log('last 5 min remaining');
    }
    if (minutes == 0 && seconds == 0) {
      this.examCompleted();
    }
    return min + ':' + sec;
  }

}
