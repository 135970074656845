import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@app/core/http/dashboard/dashboard.service';
import { AlertService } from '@app/core/services/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  router: string;
  dashboardMainData: any;
  constructor(
    private _router: Router,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService, 
    private dashboardService: DashboardService,
    ) { 
    this.router = _router.url;
  }

  ngOnInit(): void {
    this.getDashboardMain();
  }

  redirectToNofJunior(){
    this._router.navigateByUrl('nof-junior',{state: this.dashboardMainData});
  }

  getDashboardMain(){
    this.spinnerService.show(); 
    this.dashboardService.getDashboardMain().subscribe(
      (result) =>{
        this.spinnerService.hide(); 
        if (result.StatusCode === 200) {
          this.dashboardMainData = result.data;
        }
      }, (error)=>{
        this.spinnerService.hide(); 
        this.alertService.error(error.error.message);
      });
  }

}
