import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';

const routes: Routes = [
  { path: 'auth/login', component: LoginComponent },
  { path: '', redirectTo:'auth/login', pathMatch: 'full' },
  { path: 'auth', loadChildren: ()=>import('./modules/auth/auth.module').then(m=>m.AuthModule)},
  { path: '', loadChildren: ()=>import('./modules/portal/portal.module').then(m=>m.PortalModule), canLoad:[AuthGuard]},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
