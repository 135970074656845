import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


/*Models*/


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {
  }

  getDashboardMain(): Observable<any> {
    let params = {
      postType: "GET"
    };
    return this.http.post<any[]>('Mobile/NOFWeb/NOFEO/StudentRegistration', params).pipe(
      tap(
        function (data) {
          return data
        }
      )
    )
  }
  //13-11-2021
  getQuLst(classCode, subjectCode): Observable<any> {
    let params = {
    //  StudentRegistrationId: studentRegID,
      postType: "GET"
    };
    ////below giving method not allowed error
    //return this.http.post<any[]>(
    //  //'getexresdata/NOFEO/' + classCode + '/' + subjectCode, params
    //  'Mobile/NOFWeb/NOFEO/getexresdata/' + classCode + '/' + subjectCode, params
    //).pipe(
    //  tap(
    //    function (data) {
    //      return data
    //    }
    //  )
    //)

    return this.http.get<any[]>(
      //'getexresdata/NOFEO/' + classCode + '/' + subjectCode, params
      'Mobile/NOFWeb/NOFEO/getexresdata/' + classCode + '/' + subjectCode
    ).pipe(
      tap(
        function (data) {
          return data
        }
      )
    )
  }



  getStudentRegistration(studentRegID): Observable<any> {
    let params = {
      StudentRegistrationId: studentRegID,
      postType: "GET"
    };
    return this.http.post<any[]>('Mobile/NOFWeb/NOFEO/StudentRegistration', params).pipe(
      tap(
        function (data) {
          return data
        }
      )
    )
  }

  getStudentSummary(): Observable<any> {
    let params = {
      postType: "GET"
    };
    return this.http.post<any[]>('Mobile/NOFWeb/NOFEO/StudentRegistrationSummary', params).pipe(
      tap(
        function (data) {
          return data
        }
      )
    )
  }

}
