import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from './portal.component';
import { HeaderComponent } from './layouts/header/header.component';
import { LeftSidebarComponent } from './layouts/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './layouts/right-sidebar/right-sidebar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { DashboardComponent } from './/dashboard/dashboard.component';
import { NofJuniorComponent } from './nof-junior/nof-junior.component';
import { OlympiadRegisterComponent } from './olympiad-register/olympiad-register.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { StudentSummaryComponent } from './student-summary/student-summary.component';
import { ExamScheduleComponent } from './exam-schedule/exam-schedule.component';
import { ExamCompleteComponent } from './exam-complete/exam-complete.component';
import { StudentDashboardSrComponent } from './student-dashboard-sr/student-dashboard-sr.component';
import { ExamScheduleSrComponent } from './exam-schedule-sr/exam-schedule-sr.component';
import { ExamCompleteSrComponent } from './exam-complete-sr/exam-complete-sr.component';
import { ExamStartComponent } from './exam-start/exam-start.component'; 
import { ExamStartSrComponent } from './exam-start-sr/exam-start-sr.component';
import { DemoStartComponent } from './demo-start/demo-start.component';
import { DemoStartSrComponent } from './demo-start-sr/demo-start-sr.component';

const routes: Routes = [
    { path: '', component: PortalComponent, 
        children: [
          // { path: 'dashboard', component: DashboardComponent },
          { path: 'student-summary', component: StudentSummaryComponent },
          { path: 'student-registration-sr', component: StudentDashboardSrComponent },
          { path: 'student-registration', component: StudentDashboardComponent },
          { path: 'demo-exam-schedule', component: ExamScheduleComponent },
          { path: 'demo-exam-schedule-sr', component: ExamScheduleSrComponent },
          { path: 'exam-start', component: ExamStartComponent },
          { path: 'exam-start-sr', component: ExamStartSrComponent },
          { path: 'demo-exam-start', component: DemoStartComponent },
          { path: 'demo-exam-start-sr', component: DemoStartSrComponent },
          { path: 'exam-schedule', component: ExamScheduleComponent },
          { path: 'exam-schedule-sr', component: ExamScheduleSrComponent },
          { path: 'exam-complete', component: ExamCompleteComponent },
          { path: 'exam-complete-sr', component: ExamCompleteSrComponent },
          { path: 'demo-exam-complete', component: ExamCompleteComponent },
          { path: 'demo-exam-complete-sr', component: ExamCompleteSrComponent },
          
          { path: 'nof-junior', component: NofJuniorComponent },
          { path: 'olyampiad-register', component: OlympiadRegisterComponent }
        ] 
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PortalRoutingModule {
  static components = [
    PortalComponent,
    HeaderComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
    FooterComponent,
    DashboardComponent,
    NofJuniorComponent,
    OlympiadRegisterComponent,
    ExamStartComponent,
    StudentDashboardComponent,
    StudentSummaryComponent,
    ExamScheduleComponent,
    ExamCompleteComponent,
    StudentDashboardSrComponent,
    ExamScheduleSrComponent,
    ExamCompleteSrComponent,
  ];
}