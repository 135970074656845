<footer>
    <!-- <div class="mobile-footer">
      <ul>
        <li>
          <a href="">
            <i class="fa fa-home" aria-hidden="true"></i>
            <p>Home</p>
          </a>
        </li>
        <li>
          <a href="">
            <i class="fa fa-book" aria-hidden="true"></i>
            <p>Prepare</p>
          </a>
        </li>
        <li>
          <a href="">
            <i class="fa fa-cubes" aria-hidden="true"></i>
            <p>Test</p>
          </a>
        </li>
        <li>
          <a href="">
            <i class="fa fa-balance-scale" aria-hidden="true"></i>
            <p>Classroom</p>
          </a>
        </li>
        <li>
          <a href="">
            <i class="fa fa-link" aria-hidden="true"></i>
            <p>Classroom</p>
          </a>
        </li> 
      </ul>
    </div> -->
    <div class="info-sec">
      <p>
        If you have any query, write us at:
        <a href="">india@spellbeeglobal.uk</a>
        <!--<a href="">exam@groupnof.com</a>-->
      </p>
    </div>
  </footer>
