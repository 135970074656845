<div class="col-sm-3 student-name-sidebar">
    <ul class="student-name-ul">
      <li>
        <a href="">
          <div class="side-student-detail"></div>
          <p class="side-bar-sname"><b>Student's Name</b></p>
        </a>
      </li>
      <li class="padding-20">
        <a href="" [routerLink]="[ '/dashboard' ]" routerLinkActive="active">
          <i class="fa fa-home  " aria-hidden="true"></i>
          <p class="side-bar-menu">Home</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <p class="side-bar-menu">Prep zone</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-search" aria-hidden="true"></i>
          <p class="side-bar-menu">Topics</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p class="side-bar-menu">Test</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-link"></i>
          <p class="side-bar-menu">Schooling</p>
        </a>
      </li>
    </ul>
  </div>