import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '@app/core/services/message.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-exam-complete',
  templateUrl: './exam-complete.component.html',
  styleUrls: ['./exam-complete.component.css']
})
export class ExamCompleteComponent implements OnInit {
  classLevel: string = '';
  registrationID: string = '';
  constructor(
    public route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params['ClassLevel'] != ""){
        this.messageService.updateMessage({classLevel: params['ClassLevel']})
        this.classLevel = params['ClassLevel'];
      }
  
      if (params['registrationID'] != "") { // edit mode
        this.registrationID = params['registrationID'];
      } 

    });
  }

  ngAfterViewInit(): void {

    if (window.history && window.history.pushState) {
  
      $(window).on('popstate', function() {
       
        window.alert('You are logged out, as you have pressed browser back or forward.')
        sessionStorage.clear();
         window.location.reload(true);
       
      });
    }
  }

}
