import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route, UrlSegment } from '@angular/router';
import { LoggerService } from '../services/logger.service';
import { AuthenticationService } from '../authentication/authentication.service';
//import { Role } from '@app/shared/models/role.model';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
  
  constructor(
    private router: Router,
    private logger: LoggerService,
    private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.chekUser(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.chekUser(route, state);
  }

  canLoad(route: Route, state: UrlSegment[]): boolean {
    return this.chekUser(route, state);
  }

  private chekUser(route, state): boolean {
    const userType = this.authenticationService.getUserType();
    const isLogin = this.authenticationService.isLogin();
    
    if (isLogin) {
      return true;
    }  else {
      this.logger.log('Not authenticated, redirecting...');
      let stateKey = Object.keys(state);
      let stateUrl = null;
      if(state.url) {
        stateUrl = state.url;
      } else if(stateKey.length == 2){
        stateUrl = '/'+state[0].path+'/'+state[1].path;
      } else if(stateKey.length == 3){
        stateUrl = '/'+state[0].path+'/'+state[1].path+'/'+state[2].path;
      }  else if(stateKey.length == 4){
        stateUrl = '/'+state[0].path+'/'+state[1].path+'/'+state[2].path+'/'+state[3].path;
      }
      
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: stateUrl } });
      return false;
    }
  }
}

