<div class="col-sm-6 main-content-center">
    <div class="block-1 block-1-nof-junior">
        <div class="img-content-sec">
          <div class="nof-junior-img">
            <img src="assets/image/boy.png">
          </div>
          <div class="nof-junior-content">
            <h3>Register for your  Olympiads</h3>
            <p>Animation based assesments</p>
          </div>
          <div class="start-now-btn">
            <button><a href="javascript:void(0)" (click)="redirectToOlyampiadRegister()">Start now</a></button>
          </div>
        </div>
    </div>
    <div class="block-2 block-2-nof-junior">
      <div class="img-content-sec">
        <div class="nof-junior-img">
          <img src="assets/image/girl.png">
        </div>
        <div class="nof-junior-content">
          <h3>Practise Worksheet</h3>
          <p>mapped according  to school curriculam</p>
        </div>
        <div class="start-now-btn">
          <button ><a href="javascript:void(0)">Start now</a></button>
        </div>
      </div>
    </div>
    <div class="block-3 block-3-nof-junior">
      <div class="img-content-sec">
        <div class="nof-junior-img">
          <img src="assets/image/girl.png">
        </div>
        <div class="nof-junior-content">
          <h3>Reports &  Analysis</h3>
          <p>Identity  learning apps</p>
        </div>
        <div class="start-now-btn">
          <button><a href="javascript:void(0)">Start now</a></button>
        </div>
      </div>
    </div>
    <div class="block-4 block-4-nof-junior">
      <div class="img-content-sec">
        <div class="nof-junior-img">
          <img src="assets/image/girl.png">
        </div>
        <div class="nof-junior-content">
          <h3>Interactive Mock Test</h3>
          <p>Test design to score more in olympiads</p>
        </div>
        <div class="start-now-btn">
          <button><a href="javascript:void(0)">Start now</a></button>
        </div>
      </div>
    </div>
    <div class="block-4 block-5-nof-junior">
      <div class="img-content-sec">
        <div class="nof-junior-img">
          <img src="assets/image/girl.png">
        </div>
        <div class="nof-junior-content">
          <h3>Structured Remedial plan</h3>
          <p>Improvement techniques to rectify learning gaps</p>
        </div>
        <div class="start-now-btn">
          <button><a href="javascript:void(0)">Start now</a></button>
        </div>
      </div>
    </div>
  </div>
