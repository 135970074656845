import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 
/*Services*/
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AlertService } from '@app/core/services/alert.service';
import { MessageService } from '@app/core/services/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {
  totalCount:number = 4;
  cookieValue: string = "UNKNOWN";
  rendomNumber: number;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string = null;

  @ViewChild('inputPassword') inputPasswordRef: ElementRef;
  @ViewChild('passwordIcon') passwordIconRef: ElementRef;
  constructor(
    private formBuilder: FormBuilder, 
    private authenticationService : AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService, 
    private alertService: AlertService,
    private messageService: MessageService,
    private elementRef: ElementRef
    ) {}

  ngOnInit() {
    //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})");
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.compose([Validators.required])]
    });

    const isLogin = this.authenticationService.isLogin();
    if(isLogin){
      this.router.navigate(['/student-summary']);
      return true;
    }
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
  }
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundColor = '#FFFFFF';
  }
  get formObj() { return this.loginForm.controls; }


  onFormSubmit(): boolean{
    // let data = {
    //   "access_token": "2Km4SvjB3IiUxuGTsteblwpYOCmsFjzjMKIjK7udl_eva-I1w37sp_vMz2CW2kQ3hVKhGTkGb-jMIMVGO6dTRU-6Y3J3JnwOfeFk2FhVE4cqcz60J__oJnjIeCgmFZwu8ZdmfA_ooriLmjKuj029iTU-YCikw4iXe5lhdwEvMGv6SqCUzbR8E-Ik6gGJMRU_hFvPsH8bCF3mgmtbKyRJrspPHpF01fdoMG-ndFSaHHNk7L80j8v2NuZk2pebay5S63J3ACEPwA1zXj6yDNL--sofEFIlFPTjrp0mmQAqGXLYriicQcGbS7zBlLJx05-sry4lyU9OhihJMS8gQoukwA",
    //   "token_type": "bearer",
    //   "expires_in": 31535999,
    //   "AccountId": "888a0e0aa5844bc8b5fa88916cccf687",
    //   "IsCellPhoneVerified": true
    // }

    // localStorage.setItem('currentUser', JSON.stringify(data));
    // return;
    this.alertService.clear(); // clear previous alerts
    this.submitted = true;
     // stop here if form is invalid
     if (this.loginForm.invalid) {
      return;
    }

    this.spinnerService.show(); 
    let loginData = this.loginForm.value;
    this.authenticationService.login(loginData).subscribe(
      (response) =>{
        this.spinnerService.hide(); 
        
        if (response.StatusCode === 200) {
          this.messageService.updateMessage({'showClass':true});
            // set storage 
            let storage = sessionStorage;
            if(loginData.remember){
                storage = localStorage;
                response.remember = true;
            }
            response.loggedInUser = true;
            storage.setItem('currentUser', JSON.stringify(response));

            this.submitted = false;
            this.loginForm.reset(); // reset form

            if ( this.returnUrl === null) {
              this.returnUrl = '/student-summary';
            }
            this.router.navigate([this.returnUrl]);
            return true;
        } else if(response.status == 400){
          this.alertService.error(response.error.error_description);
          return;
        }
      },
      (err) =>{
        this.spinnerService.hide(); 
        this.alertService.error(err.error.error_description);
      }
    )    
  }  
  
  showHidePassword(): void {
    if(this.inputPasswordRef.nativeElement.type == "text"){
      this.inputPasswordRef.nativeElement.type = 'password';
      this.passwordIconRef.nativeElement.classList.add( "fa-eye-slash" );
      this.passwordIconRef.nativeElement.classList.remove( "fa-eye" );
    }else if(this.inputPasswordRef.nativeElement.type == "password"){
        this.inputPasswordRef.nativeElement.type = 'text';
        this.passwordIconRef.nativeElement.classList.remove( "fa-eye-slash" );
        this.passwordIconRef.nativeElement.classList.add( "fa-eye" );
    }
  }
}
