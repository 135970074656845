export class ExamJr {
  SequenceNumber: number;
  ExamType: string;
  JuniorExamName: string;
  TestSeries: string;
  ExamSeriesName: string;
  ClassCode: string;
  ClassName: string;
  SubjectCode: string;
  SubjectName: string;
  LayoutType: string;
  DifficultyLevel: string;
  QuestionTopic: string;
  QuestionText: string;
  ExamFolder: string;
  VideoFileName: string;
  AudioFileName: string;
  LastVideoFileName: string;
  ImageName: string;
  ContainerValue: string;
  OptionA: string;
  OptionB: string;
  OptionC: string;
  OptionD: string;
  CorrectOption: string;
  StudentTransactionId: string;
  OlympiadCode: string;
  OlympiadId: string;
  QuestionId: string;
  BatchId: string;
  IsLastQuestion: boolean;
  MarkAsReview: boolean;

  constructor() {
    this.SequenceNumber = 0;
    this.ExamType = "";
    this.JuniorExamName = "";
    this.TestSeries = "";
    this.ExamSeriesName = "";
    this.ClassCode = "";
    this.ClassName = "";
    this.SubjectCode = "";
    this.SubjectName = "";
    this.LayoutType = "";
    this.DifficultyLevel = "";
    this.QuestionTopic = "";
    this.QuestionText = "";
    this.ExamFolder = "";
    this.VideoFileName = "";
    this.AudioFileName = "";
    this.LastVideoFileName = "";
    this.ImageName = "";
    this.ContainerValue = "";
    this.OptionA = "";
    this.OptionB = "";
    this.OptionC = "";
    this.OptionD = "";
    this.CorrectOption = "";
    this.StudentTransactionId = "";
    this.OlympiadCode = "";
    this.OlympiadId = "";
    this.QuestionId = "";
    this.BatchId = "";
    this.IsLastQuestion = false;
  }
}

export class ExamSr {
  SequenceNumber: number;
  ExamType: string;
  SeniorExamName: string;
  TestSeries: string;
  ExamSeriesName: string;
  ClassCode: string;
  ClassName: string;
  SubjectCode: string;
  SubjectName: string;
  LayoutType: string;
  DifficultyLevel: string;
  QuestionTopic: string;
  QuestionText: string;
  ExamFolder: string;
  VideoFileName: string;
  AudioFileName: string;
  LastVideoFileName: string;
  ImageName: string;
  ContainerValue: string;
  OptionA: string;
  OptionB: string;
  OptionC: string;
  OptionD: string;
  CorrectOption: string;
  StudentTransactionId: string;
  OlympiadCode: string;
  OlympiadId: string;
  QuestionId: string;
  BatchId: string;
  IsLastQuestion: boolean;
  MarkAsReview: boolean;

  constructor() {
    this.SequenceNumber = 0;
    this.ExamType = "";
    this.SeniorExamName = "";
    this.TestSeries = "";
    this.ExamSeriesName = "";
    this.ClassCode = "";
    this.ClassName = "";
    this.SubjectCode = "";
    this.SubjectName = "";
    this.LayoutType = "";
    this.DifficultyLevel = "";
    this.QuestionTopic = "";
    this.QuestionText = "";
    this.ExamFolder = "";
    this.VideoFileName = "";
    this.AudioFileName = "";
    this.LastVideoFileName = "";
    this.ImageName = "";
    this.ContainerValue = "";
    this.OptionA = "";
    this.OptionB = "";
    this.OptionC = "";
    this.OptionD = "";
    this.CorrectOption = "";
    this.StudentTransactionId = "";
    this.OlympiadCode = "";
    this.OlympiadId = "";
    this.QuestionId = "";
    this.BatchId = "";
    this.IsLastQuestion = false;
  }
}

export class RunTestJr {
  StudentTransactionId: string;
  ClassCode: string;
  OlympiadId: string;
  BatchId: string;
  TestStatus: string;
  TestSubmissionColl: TestSubmission[];
  IsFree: boolean;

  constructor() {
    this.StudentTransactionId = "";
    this.ClassCode = "";
    this.OlympiadId = "";
    this.BatchId = "";
    this.TestStatus = "";
    this.TestSubmissionColl = [];
    this.IsFree = false;
  }
}

export class RunTestSr {
  StudentTransactionId: string;
  ClassCode: string;
  OlympiadId: string;
  BatchId: string;
  TestStatus: string;
  TestSubmissionColl: TestSubmission[];
  IsFree: boolean;
  constructor() {
    this.StudentTransactionId = "";
    this.ClassCode = "";
    this.OlympiadId = "";
    this.BatchId = "";
    this.TestStatus = "";
    this.TestSubmissionColl = [];
    this.IsFree = false;
  }
}

class TestSubmission {
  BatchId: string;
  QuestionId: string;
  ChoosenAnswer: string;
  TotalTime: number;
  SequenceNumber: number;
  MarkAsReview: boolean = false;
  constructor() {
    this.BatchId = "";
    this.QuestionId = "";
    this.ChoosenAnswer = "";
    this.TotalTime = 0
  }

}
