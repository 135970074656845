import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { LoggerService } from '../services/logger.service';
import { AuthenticationService } from '../authentication/authentication.service';
// import { appToaster } from '../../configs/app-toaster.config';
// import { ToasterService } from 'angular2-toaster';


@Injectable()
export class RouteCheckGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: LoggerService,
    //private toasterService: ToasterService,
    private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    
  }

}

