import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthenticationService } from './authentication/authentication.service';



import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { fakeBackendProvider } from './authentication/fakeapi.service';
import { AuthGuard } from './guards/auth.guard';
import { RouteCheckGuard } from './guards/route-check.guard';
import { SessionRecoveryInterceptor } from './interceptors/session-recovery.interceptor';
import { MessageService } from './services/message.service';


@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
    ],
    exports: [
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
    ],
    providers: [
      AuthenticationService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: SessionRecoveryInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiPrefixInterceptor,
        multi: true,
      },
      // TitleService,
      // StaticService,
      MessageService,
      AuthGuard,
      RouteCheckGuard,
      // provider used to create fake backend
      fakeBackendProvider
    ]
  })
  export class CoreModule extends EnsureModuleLoadedOnceGuard {    // Ensure that CoreModule is only loaded into AppModule
    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
      super(parentModule);
    }
  }
