import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MessageService {
  private myMessage = new Subject<string>(); // user for handling tabs
  private data =  new Subject<string>(); // for other use
  public dataObject: any;
  constructor() {
  }

  getMessage(): Observable<any> {
    return this.myMessage.asObservable();
  }

  updateMessage(data: any): void {
    this.myMessage.next(data);
  }

  clearMessage(){
    this.myMessage.next(null);
  }

  getData(): Observable<any> {
    return this.data.asObservable();
  }

  sendData(data: any): void {
    this.data.next(data);
  }

  clearData(){
    this.data.next(null);
  }

  setPlainData(dataObj){
    this.dataObject = dataObj;
  }

  getPlainData(){
    return this.dataObject;
  }

  clearPlainData(){
    this.dataObject = {};
  }
}

