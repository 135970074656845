<section class="container" style="min-height:500px;">
    <div class="main-enroll">
      <div class="enroll-student-bar">
        <p>Enrolled Students</p>
      </div>
      <div class="student-list">
        <ul>
          <li *ngFor="let student of studentSummary">
            <p class="name">{{student.StudentName}}</p>
            <p class="class"> {{student.Class}}</p>
            <p class="sch-name">{{student.SchoolName}}</p>
            <a href="javascript:void(0)" *ngIf="student.ClassLevel == 'JR'" style="text-align: center;" 
              [routerLink]="[ '/student-registration']" [queryParams]="{ classLevel:student.ClassLevel , registrationID: student.StudentRegistrationId }"><button>Select</button></a>
            <a href="javascript:void(0)" *ngIf="student.ClassLevel == 'SR'" style="text-align: center;" 
            [routerLink]="[ '/student-registration-sr']" [queryParams]="{ classLevel:student.ClassLevel , registrationID: student.StudentRegistrationId }"><button>Select</button></a>
          </li>
        </ul>
      </div>
    </div>
  </section>