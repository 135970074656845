import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication/authentication.service';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, public route:ActivatedRoute) {
   
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    //response.setHeader("Set-Cookie", "HttpOnly;Secure;SameSite=Strict");
    const headersConfig = {
      //'Content-Type': 'application/json',
      //'enctype': 'multipart/form-data'
      //'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    };


    const token = this.authenticationService.getToken();
    const accountId = this.authenticationService.getAccountId();
    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
      req.body.AccountId = accountId;
    }
   
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
