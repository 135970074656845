import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-nof-junior',
  templateUrl: './nof-junior.component.html',
  styleUrls: ['./nof-junior.component.css']
})
export class NofJuniorComponent implements OnInit {

  constructor(
    private _router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log(this.route);
  }

  redirectToOlyampiadRegister(){
    this._router.navigateByUrl('olyampiad-register');
  }

}
