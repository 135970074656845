<section class="container" >
	<div class="login-section">
		<div class="col-sm-6 text-center">
			<img class="logo-login" src="assets/image/spellbee-global-logo.png">
			<!--<img class="logo-login" src="assets/image/nof-logo.png">-->
		</div>
	<div class="col-sm-6">
		<div class="login-form">
			<div class="login-side bg-boxshadow">
				<i class="fa fa-info-circle" aria-hidden="true"></i>
				<p class="login-text">For your protection verify your identity	</p>
				<div class="login-head">
					<h2>Login</h2>
					<!-- <p>New user? <a routerLink="/auth/signup">Sign up</a></p> -->
		</div>
					<div class="student-tab">
						<!-- <ul class="nav nav-tabs tab-css">
						    <li class="active"><a data-toggle="tab" href="#home">Student</a></li>
						    <li><a data-toggle="tab" href="#teacher-login">Teacher</a></li>
						</ul> -->
						<div class="tab-content">
						    <!-- <div id="home" class="tab-pane  active"> -->
								<alert></alert>
								<form action="" class="form" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
									<div class="student-tab-content">
										<div class="form-group">
											<input type="text" name="" placeholder="User Name" formControlName="userName" 
											[ngClass]="{'is-invalid': submitted && formObj.userName.errors}">
											<div *ngIf="submitted && formObj.userName.errors" class="invalid-feedback">
												<div *ngIf="formObj.userName.errors.email">Invalid</div>
											</div>
										</div>
										<div class="form-group">
											<span class="pass-relative">
												<input type="password" name="" formControlName="password"  #inputPassword placeholder="Password"  [ngClass]="{'is-invalid': submitted && formObj.password.errors}">
												<a href="javascript:void(0)" (click)="showHidePassword()"><i class="fa fa-eye-slash" aria-hidden="true" #passwordIcon></i></a>
											</span>
										</div>
									</div>
									<div class="trouble-text-btn ">
										<!-- <p>Trouble loging in?<a href="">Reset Your Password</a></p> -->
										<span>
											<button type="submit" class="contiune-btn">Continue</button>
										</span>
									</div>
								</form>
						    <!-- </div> -->
						    <!-- <div id="teacher-login" class="tab-pane fade">
						      <div class="student-tab-content">
						      	<input type="email" name="" placeholder="User Name">
						      	<span class="pass-relative">
						      		<input type="password" name="" placeholder="Password">
						      		<i class="fa fa-eye" aria-hidden="true"></i>
								  </span>
								  <div class="trouble-text-btn ">
									<p>Trouble loging in?<a href="">Reset Your Password</a></p>
									<span>
										<button class="contiune-btn">Continue</button>
									</span>
								</div>
						      </div>
						    </div> -->
						</div>
				      
				      	<p class="by-logging-text">
							  By logging in, you agree to our Terms of Service
							  and receive SPELLBEE emails & updates and acknowledge that you read our 
							  Privacy Policy
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

