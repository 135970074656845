import { Component, OnInit, OnDestroy, Input,ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from '../../models/alert.model';
import { AlertService } from '@app/core/services/alert.service';

@Component({ 
    selector: 'alert', 
    templateUrl: 'alert.component.html' 
})

export class AlertComponent implements OnInit, OnDestroy {
    @Input() id = 'default-alert';
    @Input() fade = true;

   // alerts: Alert[] = [];
    alert : Alert = null;
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(private router: Router, private alertService: AlertService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService.onAlert(this.id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    this.alert = null;
                    return;
                }

                this.alert = alert
                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 3000);
                }
           });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        if( this.alertSubscription){
            this.alertSubscription.unsubscribe();
        }
        if( this.routeSubscription){
            this.routeSubscription.unsubscribe();
        }
    }

    removeAlert(alert: Alert) {
        if (this.fade) {
            // fade out alert
            if(this.alert.fade != undefined || this.alert.fade != null)
                this.alert.fade = true;
            // remove alert after faded out
            setTimeout(() => {
                this.alert = null;
                this.ref.detectChanges();
            }, 250);
        } else {
            // remove alert
            this.alert = null;
        }
    }

    cssClass(alert: Alert) {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];
                
        const alertTypeClass = {
            [AlertType.Success]: 'alert alert-success',
            [AlertType.Error]: 'alert alert-danger',
            [AlertType.Info]: 'alert alert-info',
            [AlertType.Warning]: 'alert alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }
}