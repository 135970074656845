<section class="container">
    <div class="congrats-text-sec">
      <div class="congrats-text">
        <p class="congo-text">Congratulations</p>
        <p><strong>You have successfully Completed your exam</strong></p>
        <img src="assets/image/congo.JPG">
      <div class=" go-to-btn">
        <a href="javascript:void(0)" *ngIf="registrationID != '' " [routerLink]="[ '/student-registration']"
        [queryParams]="{ ClassLevel:classLevel , registrationID: registrationID }"
      ><button>GO TO DASHBOARD</button></a>
      <a href="javascript:void(0)" *ngIf="registrationID == '' " [routerLink]="[ '/student-summary']"><button>GO TO DASHBOARD</button></a>
      </div>
      </div>
    </div>
  </section>
