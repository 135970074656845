import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { MessageService } from '@app/core/services/message.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  loggedInUser: any;
  isShowJRLogo: boolean = true;
  isShowSRLogo: boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.messageService.getMessage().pipe(debounceTime(800), distinctUntilChanged()).subscribe(data=>{
      if(data.classLevel == 'JR'){
        this.isShowSRLogo = false;
      }
      if(data.classLevel == 'SR'){
        this.isShowJRLogo = false;
      }
    })
    this.loggedInUser = this.authenticationService.getUser()
  }

  logout() {
    this.authenticationService.logout()
    window.location.reload(true);
  }

}
