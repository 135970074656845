<div class="col-sm-6 main-content-center">
    <div class="block-1">
        <div class="main-content-h1">
            <h3> NOF Junior</h3>
            <h5>(Playground to Class 5)</h5>
        </div>
        <div class="main-content-h2">
            <h3> Animation Based International Olympiads</h3>
        </div>
        <div class="main-content-h3">
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Practice Worksheets</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Mock Test</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Olmpiads Registration</p>
            </div>
        <div class="main-content-h4"> 
            <button (click)="redirectToNofJunior()">Start Now</button>
        </div>
        </div>
    </div>
    <div class="block-2">
        <div class="main-content-h1">
            <h3> NOF International Olympiads</h3>
            <h5>(class 1 to Class 12)</h5>
        </div>
        <div class="main-content-h2">
            <h3> Animation Based International Olympiads</h3>
        </div>
        <div class="main-content-h3">
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Practice Worksheets</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Mock Test</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Olmpiads Registration</p>
            </div>
        <div class="main-content-h4"> 
            <button>Start Now</button>
        </div>
        </div>
    </div>
    <div class="block-3">
        <div class="main-content-h1">
            <h3> NOF Junior</h3>
            <h5>(Playground to Class 5)</h5>
        </div>
        <div class="main-content-h2">
            <h3> Animation Based International Olympiads</h3>
        </div>
        <div class="main-content-h3">
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Practice Worksheets</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Mock Test</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Olmpiads Registration</p>
            </div>
        <div class="main-content-h4"> 
            <button>Start Now</button>
        </div>
        </div>
    </div>
    <div class="block-4">
        <div class="main-content-h1">
            <h3> NOF Junior</h3>
            <h5>(Playground to Class 5)</h5>
        </div>
        <div class="main-content-h2">
            <h3> Animation Based International Olympiads</h3>
        </div>
        <div class="main-content-h3">
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Practice Worksheets</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Mock Test</p>
            </div>
            <div class="main-content-h3-blocks">
            <img src="assets/image/game.png">
            <p>Olmpiads Registration</p>
            </div>
        <div class="main-content-h4"> 
            <button>Start Now</button>
        </div>
        </div>
    </div>
</div>
      