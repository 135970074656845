import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterDataService } from '@app/core/http/master/master-data.service';
import { OlympiadService } from '@app/core/http/olympiad/olympiad.service';
import { AlertService } from '@app/core/services/alert.service';
import { ClassMaster } from '@app/shared/models/class-master.model';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-olympiad-register',
  templateUrl: './olympiad-register.component.html',
  styleUrls: ['./olympiad-register.component.css']
})
export class OlympiadRegisterComponent implements OnInit {
  olyampiadRegisterForm: FormGroup;
  submitted = false;
  classMaster: ClassMaster[];
  constructor(
    private formBuilder: FormBuilder, 
    private masterDataService: MasterDataService,
    private olympiadService: OlympiadService,
    private router: Router, 
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService, 
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.olyampiadRegisterForm = this.formBuilder.group({
      StudentRegistrationId: [""],
      StudentName: ["",Validators.required],
      SchoolName: ["",Validators.required],
      ClassLevel: ["",Validators.required],
      Class: ["",Validators.required],
      ParentsEmail: ["",[Validators.required, Validators.email]],
       ParentsMobile: ["",Validators.required],
      AlternateMobile: [""],
      OlympiadColl: new FormArray([], [Validators.required]),
      PracticeKitColl: new FormArray([], [Validators.required]),
      AddressId: [''],
      TotalCost:[0],
    });

    this.getClassMaster();
  }

  get formObj() { return this.olyampiadRegisterForm.controls; }

  getClassMaster(){
    this.masterDataService.getClassMaster().subscribe(
      (response) =>{
        this.spinnerService.hide(); 
        if (response.StatusCode === 200) {
           this.classMaster = response.Result;
        }
      },
      (err) =>{
        this.spinnerService.hide(); 
        this.alertService.error(err.error.message);
      }
    )  
  }

  changeOlympiad(event, subject, fee){
    const formArray: FormArray = this.olyampiadRegisterForm.get('OlympiadColl') as FormArray;
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl({
              "OlympiadCode": "",
              "NameOfOlympiad": subject,
              "Fee":fee

      }));
    }
    else{
      formArray.value.find((data, index)=>{
        if(data.NameOfOlympiad == subject){
          formArray.removeAt(index);
        }
      });
    }
  }

  changePractice(event, subject, fee){
    const formArray: FormArray = this.olyampiadRegisterForm.get('PracticeKitColl') as FormArray;
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl({
        "PracticeKitId": "",
        "NameOfKit": subject,
        "Fee":fee
      }));
    }
    else{
      formArray.value.find((data, index)=>{
        if(data.NameOfKit == subject){
          formArray.removeAt(index);
        }
      });
    }
  }

  onFormSubmit(): void {
    this.submitted = true;
     // stop here if form is invalid
     if (this.olyampiadRegisterForm.invalid) {
      return;
    }

    this.spinnerService.show(); 
    let olympiadRegister = this.olyampiadRegisterForm.value;
    this.olympiadService.studentRegister(olympiadRegister).subscribe(
      (response) =>{
        this.spinnerService.hide(); 
        if (response.statusCode === 200) {
           console.log(response.result);
        }
      },
      (err) =>{
        this.spinnerService.hide(); 
        this.alertService.error(err.error.message);
      }
    )  
  }

  // {
  
  //   "AccountId": "4c0ed57480634fe58f10590b99ce7ed7",
  //   "StudentRegistrationId" : "a831e5379a4e4d81a6738494befcb229", //pass this as empty at first time registration
  //   "StudentName": "KWI",
  //   "SchoolName": "EMR",
  //   "Class": "Class 1",
  //   "ParentsMobile": "445495493434",
  //   "AlternateMobile": "34353535353",
  //   "ParentsEmail": "dineshrahav@gmail.com",
  //   "ClassLevel": "JR",
  //   "OlympiadColl" : [
  // {
  //             "OlympiadCode": "8fce3f7169414b2596da0745592f8d65",
  //             "NameOfOlympiad": "Science",
  //             "Fee": 5459.00
  
  // }
  
  // ],
  // "PracticeKitColl":[
  // {
  //             "PracticeKitId": "1f00c418e6264857b860f4a2129fcdb9",
  //             "NameOfKit": "Maths Practice Kit",
  //             "Fee": 450.00
  
  //         }
  // ],
  // "AddressId": "7c26d3ab35044da688e128ce25d2a6f5",
  // "TotalCost" : 0, //This needs to be sent empty, will be calculated on server, but will be returned in GET
  // "PaymentType" : 1,
  // "PaymentStatus" : "Payment Done", //Whatever status gateway has sent
  // "TransactionDate" : "2020-08-14 02:39:04.057", //Pass as today's date but will be calculated on server, returned in GET
  // "TransactionReferenceId" : "2424ERYEITIEUTIETETE3456783434" //Whatever status gateway has sent
  // }

}
