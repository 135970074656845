import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {WebcamModule} from 'ngx-webcam';
import { PortalComponent } from './portal.component';
import { PortalRoutingModule } from './portal-routing.modules';
import { OwlModule } from 'ngx-owl-carousel';
import { ExamStartSrComponent } from './exam-start-sr/exam-start-sr.component';
import { DemoStartComponent } from './demo-start/demo-start.component';
import { DemoStartSrComponent } from './demo-start-sr/demo-start-sr.component';




@NgModule({
  declarations: [
    PortalRoutingModule.components,
    ExamStartSrComponent,
    DemoStartComponent,
    DemoStartSrComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    WebcamModule,
    ReactiveFormsModule,
    PortalRoutingModule,
    OwlModule
  ],
  exports: [
    PortalComponent
  ]
})
export class PortalModule { }
