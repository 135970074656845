import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


/*Models*/


@Injectable({
    providedIn: 'root'
  })
export class MasterDataService {
    constructor(private http: HttpClient) {
    }

    getClassMaster(): Observable<any>{
        let params = {
            postType:"GET"
        };
        return this.http.post<any[]>('Mobile/NOFWeb/NOFEO/ClassMaster',params).pipe(
            tap(
                function (data){
                    return data
                }
            )
        )
    }

}