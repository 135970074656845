<div class="col-sm-3 queries-sidebar">
    <div class="queries-sec">
      <p>Do you have any Query?</p>
      <textarea> </textarea>
      <button class="query-side-btn">Submit</button>
    </div>
    <div class="connect-sidebar">
      <div class="connect-sec">
        <p style="color: black; ">Connect Your School </p>
        <p>Enter 5 digit School code.<br> which you receive from your school</p>
        <input type="text" name="">
        <button > Connect</button>
      </div>
    </div>
  </div>