<div class="col-sm-6 main-content-center">
    <div class="block-1 block-1-nof-junior">
        <div class="img-content-sec">
          <div class="nof-junior-img">
            <img src="assets/image/boy.png">
          </div>
          <div class="nof-junior-content">
            <h3>Register for your Olympiads</h3>
            <p>Animation based assesments</p>
          </div>
          <div class="start-now-btn">
            <button><a hre="">Start now</a></button>
          </div>
        </div>
    </div>
    <div class="register-junior-sec1">
      <div class="sec-1-head">
        <p>Section 1 of 4</p>
      </div>
      <div class="border-yellow"></div>
      <div class="register-junior-sec1-content">
        <img src="assets/image/junior.svg">
        <div class="junior-text">
          <p class="junior-ques">What is  NOF Junior?</p>
          <span class="border-bottom-yellow"></span>
          <ul>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
          </ul>
        </div>
      </div> 
      <div class="register-junior-sec1-content">
        <div class="junior-text">
          <p class="junior-ques">How to prepare  students for NOF Junior?</p>
          <span class="border-bottom-yellow"></span>
          <ul>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
          </ul>
        </div>
      </div>
      <div class="register-junior-sec1-content">
        <div class="junior-text">
          <p class="junior-ques">Recognisation & Awards?</p>
          <span class="border-bottom-yellow"></span>
          <ul>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
            <li>Animation based learning experience</li>
          </ul>
        </div>
      </div>
      <div class="sec-1-head">
        <p>Section 2 of 4</p>
      </div>
      <div class="border-yellow"></div>
      <form [formGroup]="olyampiadRegisterForm" class="section-junior-form" (ngSubmit)="onFormSubmit()">    
        <div class="register-junior-sec1-content">
            <img src="assets/image/junior.svg">
            <div class="junior-text">
                <p class="junior-ques">Students Details</p>
                <p style="font-size: 12px; color: grey;">All fields are mandatory</p>
                <span class="border-bottom-yellow"></span>
                <input id="StudentName" formControlName="StudentName" placeholder="Student's name" [ngClass]="{'is-invalid': submitted && formObj.StudentName.errors}" class="form-input" type="text" />
                <input id="SchoolName" formControlName="SchoolName"  placeholder="School name" [ngClass]="{'is-invalid': submitted && formObj.SchoolName.errors}" class="form-input" type="text" />
                <select id="ClassLevel" formControlName="ClassLevel" [ngClass]="{'is-invalid': submitted && formObj.ClassLevel.errors}">
                    <option value="">Class Level</option>
                    <option *ngFor="let class of classMaster" value="class.Level">{{class.Level}}</option>
                </select>
                <select id="Class" formControlName="Class" [ngClass]="{'is-invalid': submitted && formObj.Class.errors}">
                    <option value="">Class</option>
                    <option *ngFor="let class of classMaster" value="class.ClassName">{{class.ClassName}}</option>

                </select>
                <input id="ParentsEmail" formControlName="ParentsEmail"  placeholder="Parent's email id" [ngClass]="{'is-invalid': submitted && formObj.ParentsEmail.errors}" class="form-input" type="text" />
                <input id="ParentsMobile" formControlName="ParentsMobile"  placeholder="Parent's mobile no" [ngClass]="{'is-invalid': submitted && formObj.ParentsMobile.errors}" class="form-input" type="text" />
                <input id="AlternateMobile" formControlName="AlternateMobile"  placeholder="Alternate mobile no" [ngClass]="{'is-invalid': submitted && formObj.AlternateMobile.errors}" class="form-input" type="text" />
            </div>
        </div>
        <div class="register-junior-sec1-content">
            <img src="assets/image/junior.svg">
            <div class="junior-text">
            <p class="junior-ques">Exclusive Offer</p>
            <span class="border-bottom-yellow"></span>
            <img class="advertise-img" src="assets/image/gohan.png">
            </div>
        </div>
        <div class="sec-1-head">
            <p>Section 3 of 4</p>
        </div>
        <div class="border-yellow"></div>
        <div class="register-junior-sec1-content">
            <img src="assets/image/junior.svg">
            <div class="junior-text">
            <p class="junior-ques">Subject Selection</p>
            <p style="font-size: 12px; color: grey;">Pre nursery to class 5</p>
            <span class="border-bottom-yellow"></span>
            <p style="font-size: 12px; color: grey; margin-top: 20px;">Pick the subject that you want to participate. Child can participate in multiple Subjects </p>
                <div class="checkbox-content">
                <input type="checkbox" name="OlympiadColl" (change)="changeOlympiad($event,'Maths',300)" [ngClass]="{'is-invalid': submitted && formObj.OlympiadColl.errors}" placeholder="Student's name" >
                <label>Maths (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="OlympiadColl" (change)="changeOlympiad($event,'Science',300)" [ngClass]="{'is-invalid': submitted && formObj.OlympiadColl.errors}" placeholder="Student's name">
                <label>EVS / Science (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="OlympiadColl" (change)="changeOlympiad($event,'English',300)" [ngClass]="{'is-invalid': submitted && formObj.OlympiadColl.errors}" placeholder="Student's name">
                <label>English (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="OlympiadColl" (change)="changeOlympiad($event,'GK',300)"  [ngClass]="{'is-invalid': submitted && formObj.OlympiadColl.errors}" placeholder="Student's name">
                <label>Gk (rs.300 "Only for class 1 to 5")</label>
                </div>
            </div>
        </div>
        <div class="sec-1-head">
            <p>Section 4 of 4</p>
        </div>
        <div class="border-yellow"></div>
        <div class="register-junior-sec1-content">
            <img src="assets/image/junior.svg">
            <div class="junior-text">
            <p class="junior-ques">Practise kit Selection</p>
            <span class="border-bottom-yellow"></span>
            <textarea class="olympiad-textarea"></textarea>
            <p style="font-size: 12px; color: grey; margin-top: 20px;">Exclusive practise kit are also availabel. Students can use these kit to score more in school exams</p>
                <div class="checkbox-content">
                <input type="checkbox" name="PracticeKitColl" (change)="changePractice($event,'Maths',300)" [ngClass]="{'is-invalid': submitted && formObj.PracticeKitColl.errors}" placeholder="Student's name">
                <label>Maths (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="PracticeKitColl" (change)="changePractice($event,'Science',300)" [ngClass]="{'is-invalid': submitted && formObj.PracticeKitColl.errors}" placeholder="Student's name">
                <label>EVS / Science (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="PracticeKitColl" (change)="changePractice($event,'English',300)" [ngClass]="{'is-invalid': submitted && formObj.PracticeKitColl.errors}" placeholder="Student's name">
                <label>English (rs.300)</label>
                </div>
                <div class="checkbox-content">
                <input type="checkbox" name="PracticeKitColl" (change)="changePractice($event,'GK',300)"  [ngClass]="{'is-invalid': submitted && formObj.PracticeKitColl.errors}" placeholder="Student's name">
                <label>Gk (rs.300 "Only for class 1 to 5")</label>
                </div>
            </div>
        </div>
        <div class="continue-btn">
            <button type="submit"  class="contiune-btn" >Continue</button>
        </div>
      </form>


    </div>
  </div>
