import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner"; 

/*Service*/
import {AlertService} from '@app/core/services/alert.service';

/*Models*/
import { DashboardService } from '@app/core/http/dashboard/dashboard.service';
import { StudentSummary } from '@app/shared/models/student-summary.model';

@Component({
  selector: 'app-student-summary',
  templateUrl: './student-summary.component.html',  
  styleUrls: ['./student-summary.component.css']
})
export class StudentSummaryComponent implements OnInit {
  studentSummary: StudentSummary;
  constructor(
    public router: Router, 
    public route: ActivatedRoute,
    private spinnerService: NgxSpinnerService, 
    private alertService: AlertService,
    private dashboardService: DashboardService,
    private ref: ChangeDetectorRef,
    //private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.getStudentSummary();
  }
  ngAfterViewInit() {
    //this.elementRef.nativeElement.ownerDocument
    //window.document
     // .body.style.backgroundColor = '#F3F3F3';
  }
  
  getStudentSummary(): void{
    this.spinnerService.show(); 
    /*Set client address data*/
    this.dashboardService.getStudentSummary().subscribe(
      (response) =>{
        this.spinnerService.hide(); 
        if (response.StatusCode === 200) {
          this.studentSummary = response.Result;
        }
      }, (error)=>{
        this.spinnerService.hide(); 
        this.alertService.error(error.error.message);
      });
  }

}
