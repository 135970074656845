import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {UNAUTHORIZED, BAD_REQUEST, FORBIDDEN} from 'http-status-codes';

import { LoggerService } from '../services/logger.service';
import { environment } from '../../../environments/environment';
// import { ToasterService } from 'angular2-toaster';
// import { appToaster } from '../../configs/app-toaster.config';
import {AlertService} from '../services/alert.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { MessageService } from '../services/message.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
constructor(
    private logger: LoggerService,
    //private toasterService: ToasterService,
    private authenticateService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private alertService: AlertService
) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(response => {
      if (!environment.production) {
        // Do something with the error
        this.logger.logError('Request error ' + JSON.stringify(response));
      }
     
      const httpErrorCode = response['status'];
      switch (httpErrorCode) {
        case UNAUTHORIZED:
              this.authenticateService.logout();
              this.alertService.error("Session Expired! Try Again");
              this.router.navigateByUrl('/auth/login');
            break;
        case FORBIDDEN:
            this.alertService.error(response["message"]);
            this.router.navigateByUrl('/auth/403');
            break;
        // case BAD_REQUEST:
        //    this.showError(error.message);
        //     break;
        default:
          // console.log(typeof response.error.message);
          // this.alertService.error(response.error.message);
        //this.toasterService.pop('error', appToaster.errorHead, response['message']);
        
      }
      throw response;
    }));
  }

  // Customize the default error handler here if needed
  // private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    
  // }

}

