import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';


/*Models*/


@Injectable({
    providedIn: 'root'
  })
export class ExamService {
    constructor(private http: HttpClient) {
    }

    getExamSchedule(params): Observable<any>{
        let classLevel = params.ClassLevel == 'JR' ? 'RunTestJr': 'RunTestSr';
        return this.http.post<any[]>('Mobile/NOFWeb/NOFEO/'+classLevel, params).pipe(
            tap(
                function (data){
                    return data
                }
            )
        )
    }

    examScheduleComplete(params, apiName): Observable<any>{
        return this.http.put<any[]>('Mobile/NOFWeb/NOFEO/'+apiName, params).pipe(
            tap(
                function (data){
                    return data
                }
            )
        )
    }

    
    saveExamUserImage(params): Observable<any>{
        // const httpOptions = {
        //     headers: new HttpHeaders({        
        //       'Accept': 'application/json',
        //       'Access-Control-Allow-Origin': '*',
        //       'Content-Type': 'multipart/form-data' 
        //     })
        //   };
        return this.http.post<any[]>('NofFiles/UploadFiles', params).pipe(
            tap(
                function (data){
                    return data
                }
            )
        )
    }

}