<section class="container">
  <div class="main-enroll">
    <!-- <div class="enroll-student-bar">
    <p>Enrolled Students</p>
  </div> -->
    <div class="student-demo-sec">
      <ul>
        <li>
          <p class="name">{{studentRegistration?.StudentName}}</p>
          <p class="class">{{studentRegistration?.Class}}</p>
          <p class="sch-name">{{studentRegistration?.SchoolName}}</p>
        </li>

        <!-- <li class="doll-image">
        <p>SPELLBEE Global, London</p>
        <img src="assets/image/doll.JPG">
        <a *ngIf="studentRegistration?.DemoTestDetails.CanBeStarted"
           [routerLink]="[ '/demo-exam-start']"
           [queryParams]="{ ClassLevel:studentRegistration?.ClassLevel , ClassCode: studentRegistration.ClassCode, NameOfOlympiad:studentRegistration?.DemoTestDetails.NameOfOlympiad, OlympiadId:studentRegistration?.DemoTestDetails.OlympiadId }">
          <button class="start-demo"> Start Demo</button>
        </a>
      </li>-->
      </ul>
    </div>
    <div class="enroll-student-bar">
      <!--<p>NOF Junior International Olympiads</p>-->
      <p>SPELLBEE Global, London</p>
    </div>
    <div class="olym-sub">
      <ul>
        <li *ngFor="let exam of studentRegistration?.ExamScheduleColl">
          <img src="{{exam.ImageUrl}}">
          <p class="sub">{{exam.NameOfOlympiad}}</p>
          <p class="hours" *ngIf="!exam.CanBeStarted" [hidden]="exam.IsCompleted">{{exam.RemainingTimeMessage}}</p>
          <a *ngIf="exam.CanBeStarted" [routerLink]="[ '/exam-start']"
             [queryParams]="{ ClassLevel:studentRegistration?.ClassLevel , ClassCode: studentRegistration.ClassCode, OlympiadId:exam.OlympiadId,NameOfOlympiad:exam.NameOfOlympiad, StudentTransactionId:exam.StudentTransactionId }"><button>Start Exam</button></a>

          <button *ngIf="!exam.CanBeStarted && !exam.IsCompleted" style="background: #ccc;">Start Exam</button>
          <button *ngIf="exam.IsCompleted" [hidden]="!exam.IsCompleted" style="background: #ccc;">COMPLETED</button>
          <!--Uncomment and implement this for result link-->
          <!--

          [hidden]="!exam.CanBeStarted && !exam.IsCompleted"
  && exam.Total > 0
  && exam.Grade.length>0
            -->
          <br /><a *ngIf="((!exam.CanBeStarted || exam.IsCompleted) && exam.Grade.length>0 )" href="javascript:void(0)" [routerLink]="exam.ExamResultUrl">View Result</a>

          <button *ngIf="((!exam.CanBeStarted || exam.IsCompleted) && exam.Grade.length>0 ) " (click)="downloadCert(exam.NameOfOlympiad,exam.Total,exam.Grade)">Download e-Certificate</button>
          <button *ngIf="((!exam.CanBeStarted || exam.IsCompleted) && exam.Grade.length>0)  " (click)="downloadRepo(exam)">Download e-Report</button>
          <!-- -->
        </li>
    </ul>
  </div>

  <h3 class="text-center">SPELLBEE Global Exam Instructions</h3>
  <!--<h3 class="text-center">NOF Junior International Olympiads Instructions</h3>-->
          <!--<instructions></instructions>-->
          <b>Please allow the camera permission (access) when asked.</b>
          <ol>
            <li>This SPELLBEE Global Exam has <b>30 MCQs</b> (Multiple Choice Questions).</li>
            <li>The student needs to click on the correct option from the multiple choices. <b>There can only be one correct answer.</b></li>
            <li>There is <b>NO NEGATIVE marking.</b></li>
            <li><b>The duration of the exam is 45 minutes.</b></li>
            <li>This exam has some questions based on AUDIO. Make sure your device is audio enabled. It is suggested to use headphones/earphones for better understanding</li>
            <li>Parents of children from UKG to Grade 1 may assist their wards in understanding the questions <b>if needed.</b></li>
            <li>If due to any reason (technical, loss of internet, system crash, server problem or any other valid reason) the exam stops, a child can restart the exam. <b>ONLY TWO such attempts are allowed.</b></li>
            <li>At any point during the exam <b>do not refresh or click on the back button of the browser</b> as this will log you out of the examination.</li>
            <li><b>Use a fast reliable and continued internet connection.</b> Do not use an internet connection that frequently disconnects. A minimum of 512 kbps upload speed is preferred.</li>
            <li>Kindly ensure that you have the latest version of Google Chrome. For Mac users, do not use the Safari browser, <b>USE ONLY GOOGLE CHROME.</b></li>
            <li>This exam is powered by video proctoring hence <b>any unneeded interference by any adult during the exam will be monitored and may lead to disqualification of the exam. Hence you have to ALLOW the camera to be on.</b></li>
            <li>To have a great user experience, prefer attempting the exam on a laptop/ desktop. However, the exam can also be attempted on a smartphone.</li>
          </ol>
          For any doubts or queries, write us at india@spellbeeglobal.uk
</div>
  </section>
