import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appSSNMask]',
})
export class SSNMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if(event !== null) {
      this.onInputChange(event, false);
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }
  

  onInputChange(event, backspace) {
    if(event){
        let newVal = event.replace(/\D/g, '');
        if (backspace && newVal.length > 9) {
           newVal =newVal.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
        }
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length > 4) {
          newVal = newVal.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/,"$1-$2-$3");
        } else if (newVal.length > 2) {
          newVal = newVal.replace(/^(\d\d\d)(\d{0,3})/,"$1-$2");
        } else {
         // newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d*)/, "$1");
        }
        this.ngControl.valueAccessor.writeValue(newVal);
      }
    }
}