import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownDirective } from './directives/dropdown.directive';
import {AlertComponent} from './components/alert/alert.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import {SSNMaskDirective} from './directives/SSN-mask.directive';
import {CustomDateFormatterService} from './services/custom-date-formatter.service';
//import {CustomDateAdapterService} from './services/custom-date-adapter.service';
import {NgbDateParserFormatter, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import { InstructionsComponent } from './components/instructions/instructions.component';
@NgModule({
  declarations: [
    DropdownDirective,
    PhoneMaskDirective,
    SSNMaskDirective,
    AlertComponent,
    InstructionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule      
  ],
  exports: [
    CommonModule,
    DropdownDirective,
    PhoneMaskDirective,
    SSNMaskDirective,
    AlertComponent,
    InstructionsComponent
  ],
  providers: [
    //{provide: NgbDateAdapter, useClass: CustomDateAdapterService},
    { provide: NgbDateParserFormatter, useClass: CustomDateFormatterService }
  ],
  
})
export class SharedModule { }
