<!DOCTYPE html>
<html>
<head>
	<title>Portal</title>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <!-- <link rel="stylesheet" href="../../../assets/css/bootstrap.min.css"> -->
    <!-- <link rel="stylesheet" href="../../../assets/css/owl.carousel.min.css">
    <link rel="stylesheet" href="../../../assets/css/owl.theme.default.min.css"> -->
    <!-- <link rel="stylesheet" type="text/css" href="../../../assets/css/font-awesome.min.css">
    <link href="../../../assets/css/style.css" rel="stylesheet"> 
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script> -->
</head>
<body [ngClass]="{'back-class': isLoggedIn}" style="min-height:650px;background:#F3F3F3">
<app-header *ngIf="showHeader"></app-header>
<section class="container">
    <div class="banner" *ngIf="showBanner == '/dashboard'">
        <!-- <img src="assets/image/gohan.png"> -->
         <!-- <owl-carousel [options]="SlideOptions" [items]="images" [carouselClasses]="['owl-theme', 'sliding']" >  
                <div class="item" *ngFor="let img of Images">  
                <div style="align-content: center">  
                <img style="height: 260px;width:100%"src={{img}}/>  
                </div>  
                </div>  
                </owl-carousel>     -->
    </div>
    <div class="main-content">
        <!-- <app-left-sidebar></app-left-sidebar> -->
        <router-outlet></router-outlet>
        <!-- <app-right-sidebar></app-right-sidebar> -->
    </div>
</section>
<app-footer *ngIf="showHeader"></app-footer>
</body>
</html>
