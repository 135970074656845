<section class="container">
  <div class="main-enroll">
    <div *ngIf="singleExam?.SequenceNumber == sequenceNumber">
      <div class="layout-variation">
        <a href="javascript:void(0)" *ngIf=" isQuestionLoad && singleExam.AudioFileName != ''" (click)="audioPlay(singleExam.AudioFileName)">
          <img src="assets/image/audio-reload.png"> Listen to your question
        </a>
        <p class="text-right" style="float: right;">
          <i class="fa fa-clock-o" aria-hidden="true"></i>
          {{display}} <webcam *ngIf="isQuestionLoad" [height]="150" [width]="140"
                        [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                        [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" 
                        [videoOptions]="videoOptions"
                        [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                  (initError)="handleInitError($event)"></webcam>
        </p>

        <div *ngIf="!isQuestionLoad">
          <div *ngIf="singleExam.VideoFileName != null">
            <video width="100%" height="100%" controls disablepictureinpicture controlslist="nodownload" autoplay (ended)="videoEnd()">
              <source [attr.src]="singleExam.VideoFileName" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
                
        <div class="text-section" [ngClass]="{'revdiv' : isMobileDevice}" *ngIf="isQuestionLoad" style="margin-bottom: 28px;">
          <div [ngClass]="singleExam.ContainerValue != null ? 'nof-width-75' : 'nof-width-100' ">
            <!--<p><strong>{{singleExam.QuestionText}} </strong></p>-->
            <p><strong [innerHtml]="singleExam.QuestionText"></strong></p>
            <div class="ab-option">
              <ul>
                <li>
                  <button [ngClass]="{'selected': currentChoosenAnswer== 'A' }" *ngIf="singleExam.OptionA != null" (click)="selectOptions('A', singleExam)"><b>A.</b> {{singleExam.OptionA}}</button>
                </li>
                <li>
                  <button [ngClass]="{'selected': currentChoosenAnswer== 'B' }" *ngIf="singleExam.OptionB != null" (click)="selectOptions('B', singleExam)"><b>B.</b> {{singleExam.OptionB}}</button>
                </li>
                <li>
                  <button [ngClass]="{'selected': currentChoosenAnswer== 'C' }" *ngIf="singleExam.OptionC != null" (click)="selectOptions('C', singleExam)"><b>C.</b> {{singleExam.OptionC}}</button>
                </li>
                <li>
                  <button [ngClass]="{'selected': currentChoosenAnswer== 'D' }" *ngIf="singleExam.OptionD != null" (click)="selectOptions('D', singleExam)"><b>D.</b> {{singleExam.OptionD}}</button>
                </li>
              </ul>
            </div>
          </div>
          <div class="img-sec" *ngIf="singleExam.LayoutType == 'JL1' || singleExam.LayoutType == 'JL2'">
            <img [attr.src]="singleExam.ContainerValue">
          </div>
          <div class="scroll-text-sec" *ngIf="singleExam.LayoutType == 'JL3' || singleExam.LayoutType == 'JL4'">
            <div class="scroll-text">
              <!--  <p>{{singleExam.ContainerValue}}</p>-->
              <p><strong [innerHtml]="singleExam.ContainerValue"></strong></p>
            </div>
          </div>
        </div>

        <div style="font-size:20px" *ngIf="isQuestionLoad">
          <div class="row">
            <div style="float: right; margin-right: 15px;">
              <label style="color:#9D7BEE">
                <input type="checkbox" [(ngModel)]="singleExam.MarkAsReview" (change)="markChanged($event)" /> I Will Solve It Later
              </label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 34px;">
            <div class="col-xs-4">
              <button class="next-prev-btn" *ngIf="sequenceNumber>1" (click)="previous(sequenceNumber)">< PREV</button>
            </div>
            <div class="col-xs-4 text-center">
              <span>Q. {{singleExam.SequenceNumber}}</span>
            </div>
            <div *ngIf="!singleExam.IsLastQuestion" class="col-xs-4 text-right">
              <!--<a href="javascript:void(0)" (click)="next(singleExam.SequenceNumber)">Next</a>-->
              <button class="next-prev-btn" (click)="next(sequenceNumber)">NEXT ></button>
            </div>
          </div>
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
              <div class="panel-heading active" role="tab" id="headingOne" style="background: #77D894 0% 0% no-repeat padding-box;">
                <h4 class="panel-title">
                  <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Question Palette
                  </a>
                </h4>
              </div>
              <div id="collapseOne" class="panel-collapse collapse in" style="background: #9FEBFF 0% 0% no-repeat padding-box;" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <ul class="palette-qlist">
                    <li *ngFor="let item of examSubmition.TestSubmissionColl" (click)="goToQues(item.SequenceNumber)" [ngStyle]="{'backgroundColor': item.MarkAsReview==true ? '#9D7BEE' : item.ChoosenAnswer !='' ? '#77D894' : item.TotalTime>0 ? '#EE7B7B' : '#fff'}">{{item.SequenceNumber}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-right" *ngIf="showConfirm">

          </div>
          <div class="row" style="margin: 0;padding: 0;">
            <ul class="legend" style="margin-left: 15px;">
              <li *ngFor="let item of legendArr">
                <span [ngStyle]="{'background':item.txt == 'I Will Solve It Later' ? '#9D7BEE' : item.txt == 'Yet To Answer' ? '#EE7B7B' : item.txt == 'Answered' ? '#77D894' : '#FFFFFF'}">
                  {{item.cnt}}
                </span>
                {{item.txt}}
              </li>
            </ul>
            <div *ngIf="showConfirm" style="margin: 0 6px;display: inline-block;float:right;">
              <p>
                <b> Are You Sure? </b>
              </p> <button (click)="submitConfirmClicked()">Yes</button><button (click)="showConfirm=false">No</button>
            </div>
            <button class="submit-button" *ngIf="!showConfirm" (click)="showConfirm=true">SUBMIT EXAM</button>
          </div>


        </div>

      </div>
    </div>
    <div *ngIf="isLastVideoPlay">
      <div class="layout-variation">
        <div *ngIf="singleExam.LastVideoFileName != null || singleExam.LastVideoFileName != ''">
          <video width="100%" height="100%" controls disablepictureinpicture controlslist="nodownload" autoplay (ended)="lastVideoEnd()">
            <source [attr.src]="singleExam.LastVideoFileName" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>



  </div>
</section>

