import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import $ from 'jquery';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { MessageService } from '@app/core/services/message.service';


@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {
  Images = ['../assets/images/Carousel1.jpeg', '../assets/images/Carousel2.jpeg', '../assets/images/Carousel3.jpeg'];  
  
  SlideOptions = { items: 1, dots: true, nav: true };  
  CarouselOptions = { items: 3, dots: true, nav: true }; 
  showBanner: string; 
  showHeader: boolean = true;
  isLoggedIn: boolean = false;
  constructor(
    private authenticateService: AuthenticationService, 
    private messageService: MessageService,
    public router: Router
  ) { 
    
      router.events.subscribe((event) => {
        if(event instanceof NavigationEnd){
        let eventUrl = event.url.split('?')[0];
        if(eventUrl === '/exam-schedule' || eventUrl === '/exam-schedule-sr' || eventUrl === "/demo-exam-schedule" || eventUrl === "/demo-exam-schedule-sr" ){
          this.showHeader = false
        } else {
          this.showHeader = true;
        }
      }
    });

  }
  
  
  ngOnInit(): void { 
    // $(document).on("keydown",function(ev){
    //   console.log(ev.keyCode);
    //   if(ev.keyCode===27||ev.keyCode===122) {return false}
    // })
    document.addEventListener('contextmenu', event => event.preventDefault()); //right click disabled
    //this.openfullscreen();
    this.isLoggedIn = this.authenticateService.isLogin();
    this.messageService.getMessage().subscribe(data =>{
      console.log(data);
      this.isLoggedIn = data.showClass;
    })
  }

  openfullscreen() {
     // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
    //this.isfullscreen = true;
  }
}
