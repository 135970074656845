<header>
  <div class="header container">
    <div class="logo logo-60">
      <i class="fa fa-bars menu-bars" aria-hidden="true"></i>
      <img src="assets/image/spellbee-blobal-h-logo.png">
      <!--<img src="assets/image/nof.JPG" *ngIf="isShowSRLogo">-->
      <!--<img src="assets/image/nof-junior-logo.png" *ngIf="isShowJRLogo" >-->
    </div>
  <div class="head-mail">
    <p>{{loggedInUser?.UserName}}</p>
  </div>
  <div class="head-logout-btn">
    <a href="javascript:void(0)" (click)="logout()"> Logout</a>
  </div>
  <div class="head-home">
    <i class="fa fa-home" aria-hidden="true"></i>
  </div>
  <!-- <div class="student-name-sidebar">
    <ul class="student-name-ul display-nav-side">
      <li>
        <a href="">
          <div class="side-student-detail"></div>
          <p class="side-bar-sname"><b>Student's Name</b></p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-home  " aria-hidden="true"></i>
          <p class="side-bar-menu">Home</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <p class="side-bar-menu">Prep zone</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-search" aria-hidden="true"></i>
          <p class="side-bar-menu">Topics</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p class="side-bar-menu">Test</p>
        </a>
      </li>
      <li class="padding-20">
        <a href="">
          <i class="fa fa-link"></i>
          <p class="side-bar-menu">Schooling</p>
        </a>
      </li>
    </ul>
  </div> -->
    </div>
</header>
