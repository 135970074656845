import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { MessageService } from '@app/core/services/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@app/core/services/alert.service';
import { ExamService } from '@app/core/http/exam/exam.service';


@Component({
  selector: 'app-exam-start',
  templateUrl: './exam-start.component.html',
  styleUrls: ['./exam-start.component.css']
})
export class ExamStartComponent implements OnInit {
     // toggle webcam on/off
     
     public showWebcam = true;
     public allowCameraSwitch = false;
     public multipleWebcamsAvailable = false;
     public deviceId: string;
     public videoOptions: MediaTrackConstraints = {
       // width: {ideal: 1024},
       // height: {ideal: 576}
     };
     public errors: WebcamInitError[] = [];
   
     // latest snapshot
     public webcamImage: WebcamImage = null;
   
     // webcam snapshot trigger
     private trigger: Subject<void> = new Subject<void>();
     // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
     private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
     public imageArray = [];
    ClassCode: string = '';
    ClassLevel: string = '';
    OlympiadId: string = '';
    StudentTransactionId: string = '';
    NameOfOlympiad: string = '';
    startExamDisable: boolean = true;
    isStartExamBtn: boolean = false;
  constructor(
    public router: Router, 
    public route: ActivatedRoute,
    public messageService: MessageService,
    private spinnerService: NgxSpinnerService, 
    private alertService: AlertService,
    private examService: ExamService,
  ) {}

  public ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params['ClassLevel'] != ""){
        this.messageService.updateMessage({classLevel: params['ClassLevel']})
        this.ClassLevel = params['ClassLevel'];
      }
      
      if(params['ClassCode'] != ""){
        this.ClassCode = params['ClassCode'];
      }
      if(params['OlympiadId'] != ""){
        this.OlympiadId = params['OlympiadId'];
      } 
      if(params['StudentTransactionId'] != ""){
        this.StudentTransactionId = params['StudentTransactionId'];
      }
      if(params['NameOfOlympiad'] != ""){
        this.NameOfOlympiad = params['NameOfOlympiad'];
      }
    });


    
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    }

  startExam(){
    
    this.route.queryParams.subscribe(params => {
      this.ClassCode = params['ClassCode'];
      this.ClassLevel = params['ClassLevel'];
      this.OlympiadId = params['OlympiadId'];
      this.StudentTransactionId = params['StudentTransactionId'];
      
      if (this.ClassCode && this.ClassLevel && this.OlympiadId && this.StudentTransactionId) { // edit mode
        this.router.navigate([ '/exam-schedule'], {
          queryParams: {ClassLevel: params['ClassLevel'], ClassCode: params['ClassCode'], OlympiadId: params['OlympiadId'], StudentTransactionId: params['StudentTransactionId']}
        });
      }else if (this.ClassCode && this.ClassLevel && this.OlympiadId) { // edit mode
        this.router.navigate([ '/demo-exam-schedule'], {
          queryParams: {ClassLevel: params['ClassLevel'], ClassCode: params['ClassCode'], OlympiadId: params['OlympiadId']}
        });
      }
    });
    

  }
 
   public triggerSnapshot(): void {
     this.trigger.next();
   }
 
   public toggleWebcam(): void {
     this.showWebcam = !this.showWebcam;
   }
 
  //  public handleInitError(error: WebcamInitError): void {
  //    this.errors.push(error);
  //  }

   public handleInitError(error: WebcamInitError): any {
    if (error.mediaStreamError) {
      this.isStartExamBtn = true;
      this.alertService.error('Web cam not found, please enable camera for browser.');
      return false;
    } else {
      this.isStartExamBtn = false;
    }
  }
 
   public showNextWebcam(directionOrDeviceId: boolean|string): void {
     // true => move forward through devices
     // false => move backwards through devices
     // string => move to device with given deviceId
     this.nextWebcam.next(directionOrDeviceId);
   }
   
    public handleImage(webcamImage: WebcamImage): void {
      //console.info('received webcam image', webcamImage);
      this.webcamImage = webcamImage;
      this.imageArray.push(this.webcamImage);
     
    }
 
   public cameraWasSwitched(deviceId: string): void {
     console.log('active device: ' + deviceId);
     this.deviceId = deviceId;
   }
 
   public get triggerObservable(): Observable<void> {
     return this.trigger.asObservable();
   }
 
   public get nextWebcamObservable(): Observable<boolean|string> {
     return this.nextWebcam.asObservable();
   }

   public removeImage(){
     this.imageArray = [];//this.imageArray.filter((element,index)=> index != imageIndex);
   }
   public dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  uploadImage(){
    const formData =  new FormData();
    for  (let i =  0; i <  this.imageArray.length; i++)  {  
      let fileObj = this.dataURLtoFile(this.imageArray[i].imageAsDataUrl,Date.now() + '.jpeg');
      // var link = document.createElement("a");
      // var file = fileObj;
      
      // link.download = fileObj.name;
      // link.href = URL.createObjectURL(file);
      // link.click();
        formData.append('file'+i, fileObj,fileObj.name);
    } 
    //console.log(this.imageArray[0]);
    //let fileObj = this.dataURLtoFile(this.imageArray[0].imageAsDataUrl,'hello.png');
    //formData.append('file0', fileObj,fileObj.name);

    formData.append('StudentTransactionId', this.StudentTransactionId);
    formData.append('ClassCode', this.ClassCode);
    formData.append('OlympiadId', this.OlympiadId);
    formData.append('IsFree', '0');
    

    this.spinnerService.show(); 
    this.examService.saveExamUserImage(formData).subscribe(
      (response) =>{
        if (response.StatusCode === 200) {
          this.spinnerService.hide(); 
          console.log(response);
          //this.startExamDisable = false;
        }
      },
      (err)=>{
        this.spinnerService.hide(); 
        this.alertService.error(err.error.message);
      });
  }


  }
